import api from 'app/utils/api';

export class AddressService {
  static async getPlaceSuggestions(inputValue: string) {
    try {
      const response = await api.get(`/places/autocomplete`, {
        params: { input: inputValue },
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async getPlaceDetails(placeID: string) {
    try {
      const response = await api.get(`/places/place-details`, {
        params: { placeID },
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async validateAddress(placeId: string, unitNumber: string) {
    const query = new URLSearchParams({
      placeId,
      unitNumber,
    });
    try {
      const response = await api.get(`/transaction/validate-address?${query}`);
      return response;
    } catch (error) {
      return error;
    }
  }
}

import ComplianceDashboard from 'app/pages/Compliance/Dashboard';

// const prefix = '/v2';
const prefix = '';

export const AppURLs = {
  // Auth
  agentLogin: () => prefix + '/agent/login',
  agentLoginSessionExpired: () => prefix + '/agent/login?sessionExpired=true',

  complianceLogin: () => prefix + '/compliance/login',
  complianceLoginSessionExpired: () =>
    prefix + '/compliance/login?sessionExpired=true',

  // Agent Onboarding
  agentOnboardingAddEmail: (partnerID: string = '') =>
    prefix + `/agent/onboarding/add-email?partnerID=${partnerID}`,

  // Agent Dashboard Main
  agentDashboardMainHome: () => prefix + '/agent/dashboard/main/home',
  agentDashboardMainAddresses: () => prefix + '/agent/dashboard/main/addresses',
  agentDashboardMainContacts: () => prefix + '/agent/dashboard/main/contacts',
  agentDashboardMainClients: () => prefix + '/agent/dashboard/main/clients',

  // Agent Dasboard Property Task
  agentDashboardPropertyBase: () => prefix + `/agent/dashboard/property`,
  agentDashboardPropertyDetails: (token: string) =>
    prefix + `/agent/dashboard/property/${token}`,
  agentDashboardPropertyTasks: (token: string, selectedTaskID: string) =>
    prefix +
    `/agent/dashboard/property/${token}/tasks?selectedTaskID=${selectedTaskID}`,

  // Agent Dashboard Buyer Agency Agreement
  agentDashboardBuyerAgencyAgreement: () =>
    prefix + '/agent/dashboard/buyer-agency-agreement',

  // Compliance Dashboard
  complianceDashboardTransactions: () =>
    prefix + '/compliance/dashboard/transactions',
  complianceDashboardChecks: () => prefix + '/compliance/dashboard/checks',
  complianceDashbaordWorkAllocation: () =>
    prefix + '/compliance/dashboard/work-allocation',
  complianceDashboardTransaction: (transactionID: string) =>
    prefix +
    `/compliance/dashboard/transactions/documents?transactionID=${transactionID}`,
  complianceDashboardTransactionContacts: (transactionID: string) =>
    prefix +
    `/compliance/dashboard/transactions/contacts?transactionID=${transactionID}`,
  complianceDashboardTransactionFacts: (transactionID: string) =>
    prefix +
    `/compliance/dashboard/transactions/facts?transactionID=${transactionID}`,
  complianceDashboardTransactionVerifyDocument: (
    transactionID: string,
    documentID: string
  ) =>
    prefix +
    `/compliance/dashboard/transactions/verify?transactionID=${transactionID}&documentID=${documentID}`,
  complianceDashboardValidation: (checkID: string) =>
    prefix + `/compliance/dashboard/validation?checkID=${checkID}`,

  // Office Admin
  officeAdminChecks: () => prefix + '/office-admin/dashboard/checks',

  // Shared
  privacyPolicy: () => prefix + '/privacy-policy',
};

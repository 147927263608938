import React, { useEffect, useState } from 'react';
import { TextField, List, ListItem, Paper } from '@mui/material';
import { AddressService } from './AddressService/AddressService';

interface AutocompleteInputProps {
  value: string;
  onAddressSelect: (selectedAddress: any) => void;
  onInputChange: (value: string) => void;
}

const AutocompleteInput: React.FC<AutocompleteInputProps> = ({
  value,
  onAddressSelect,
  onInputChange,
}) => {
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [query, setQuery] = useState<string>(value);

  const handleAutocomplete = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setQuery(inputValue);
    onInputChange(inputValue); // Call this to reset the address object in parent

    if (inputValue.length > 2) {
      const response: any =
        await AddressService.getPlaceSuggestions(inputValue);
      setSuggestions(response.data);
    } else {
      setSuggestions([]);
    }
  };

  const handleSelectSuggestion = (suggestion: any) => {
    setSuggestions([]);
    setQuery(suggestion.description);
    onAddressSelect({
      description: suggestion.description,
      placeId: suggestion.place_id,
    });
  };

  useEffect(() => {
    setQuery(value);
  }, [value]);

  useEffect(() => {
    const fetchSuggestions = async () => {
      const response: any = await AddressService.getPlaceSuggestions(value);
      setSuggestions(response.data);
    };
    fetchSuggestions();
  }, []);

  return (
    <div style={{ position: 'relative' }}>
      <TextField
        fullWidth
        label="Search Address..."
        value={query}
        onChange={handleAutocomplete}
      />
      {suggestions.length > 0 && (
        <Paper
          style={{
            position: 'absolute',
            zIndex: 1000,
            width: '100%',
          }}
        >
          <List>
            {suggestions.map((suggestion: any, index) => (
              <ListItem
                button
                key={index}
                onClick={() => handleSelectSuggestion(suggestion)}
              >
                {suggestion.description}
              </ListItem>
            ))}
          </List>
        </Paper>
      )}
    </div>
  );
};

export default AutocompleteInput;
